.loading {
  position: fixed;
  z-index: 999;
  overflow: visible;
  text-align: center;
  vertical-align: middle;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.loading > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
