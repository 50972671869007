.wizard {
  height: 500px;
  display: flex;
  flex-direction: row;

  .stepper {
    width: 250px;
    min-width: 250px;
    border-right: 1px solid #f0f0f0;
  }

  .step {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    padding-left: 24px;

    .step-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: auto;

      :global(.ant-result) {
        padding-top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
      }

      .search {
        align-self: flex-end;
        margin-bottom: 4px;
      }

      .step-text {
        margin-bottom: 8px;
      }

      .text {
        margin-top: 4px;
      }
    }

    .button-bar {
      height: 32px;
      box-sizing: content-box;
      padding: 10px 16px 0 16px;
      border-top: 1px solid #f0f0f0;

      :global(.button-next) {
        float: right;
      }
    }
  }
}

.vertical-list {
  display: flex;
  flex-direction: column;
}
