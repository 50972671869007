.ant-breadcrumb span:last-child {
  a {
    cursor: inherit;
  }
}

.ant-table-row {
  cursor: pointer;
  vertical-align: top;
}
