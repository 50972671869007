.ant-tabs-tab {
  .error {
    color: red;

    .anticon {
      margin-left: 8px;
      margin-right: 0 !important;
    }
  }
}

.safeModeActivated {
  color: #52c41a;
  border-color: #52c41a;
}

.safeModeDisabled {
  color: #f5222d;
  border-color: #ffa39e;
}

.blinker {
  animation: blink 2s ease-in-out infinite;
  -webkit-animation: blink 2s ease-in-out infinite;
}
@keyframes blink {
  to {
    color: #262626;
  }
}
@-webkit-keyframes blink {
  to {
    color: #262626;
  }
}
