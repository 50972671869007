.wizard {
  height: 500px;
  display: flex;
  flex-direction: row;

  .stepper {
    width: 250px;
    min-width: 250px;
    border-right: 1px solid #f0f0f0;
  }

  .step {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    padding-left: 24px;

    .step-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: auto;

      .step-text {
        margin-bottom: 8px;
      }
    }

    .step-template {
      .creation-mode {
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;

        label + label {
          margin-top: 4px;
        }
      }

      :global(.ant-select) {
        width: 300px;
      }
    }

    .step-views {
      padding-bottom: 24px;

      :global(.ant-card) {
        margin-right: 24px;

        :global(.ant-card-body) {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;

          label {
            width: 50%;
            margin-left: 0;
          }
        }
      }

      :global(.ant-card) + :global(.ant-card) {
        margin-top: 8px;
      }
    }

    .step-summary {
      .property {
        margin-bottom: 2px;
        display: flex;
        flex-direction: row;

        .name {
          width: 150px;
          font-weight: bold;
        }

        .views {
          display: flex;
          flex-direction: column;

          .view {
            .image-names {
              font-size: 12px;
              font-style: italic;
            }
          }

          .view + .view {
            margin-top: 8px;
          }
        }
      }
    }

    .button-bar {
      height: 32px;
      box-sizing: content-box;
      padding: 10px 16px 0 16px;
      border-top: 1px solid #f0f0f0;

      :global(.button-next) {
        float: right;
      }
    }
  }
}
