.ant-card-body {
  min-height: 75px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-card-meta-title {
    text-align: center;
    white-space: normal;
  }
}
