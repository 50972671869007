.ant-upload.ant-upload-drag .ant-upload {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 0;
}

.ant-upload.ant-upload-drag {
  border-width: 0px;
}

.drag-overlay {
  transition: 0.3s ease;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
}

.drag-icon-and-text {
  transition: 0.3s ease;
  position: absolute;
  width: 250px;
  height: 100px;
  margin-left: -125px;
  margin-top: -50px;
  top: 50%;
  left: 50%;
  text-align: center;
  background-color: black;
  color: white;
}

.drag-icon {
  font-size: 40px;
  padding: 20px;
}

.drag-text {
  font-size: 20px;
}

.ReactCrop__image {
  max-height: none !important;
  min-height: fit-content !important;
}

.drag-container {
  min-height: 100%;
  width: 100%;
}

/*.drag-container:hover .ReactCrop__image, .drag-container-image-hover {
  opacity: 0.4;
}

.drag-container:hover .drag-underlay, .drag-underlay-hover {
  opacity: 0.6;
}

.drag-container:hover .drag-icon-and-text {
  opacity: 1;
}*/
