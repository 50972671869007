body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.logo img {
  margin: 12px 24px;
}

.logo {
  min-height: 60px;
}

.home-logo {
  width: 100%;
  min-height: 360px;
  vertical-align: middle;
  text-align: center;
}

.home-logo img {
  margin-top: 120px;
}
