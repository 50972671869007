.document-template-tree-item {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 6px;
  cursor: pointer;
  border-radius: 2px;
  flex: 1;

  Button {
    margin-left: 4px;
  }

  &:hover {
    background-color: #eee;
  }

  &.selected {
    background-color: #337ab7 !important;
    color: #fff;
  }

  &.viewTemplate {
    margin-left: 6px;
  }
  &.imageTemplate {
    margin-left: 32px;
  }
}

.document-template-tree {
  display: flex;
  justify-items: center;
}
