.ant-form-item {
  margin-bottom: 12px;
}

.ant-skeleton-content {
  padding-top: 20px;
  padding-left: 20px;
}

.ant-skeleton-content .ant-skeleton-paragraph li {
  height: 30px;
}

.ant-skeleton-content .ant-skeleton-paragraph li + li {
  margin-top: 20px;
}
